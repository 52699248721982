<template>
    <div class="newGoods">
        <swiper :options="swiperOption" class="swiper-container swiper-pagination1" ref="mySwiper">
            <!-- 添加的图片 -->
            <swiper-slide v-for="(item,index) in banner">
                <!-- id:item.rid!=1?item.rid:index==2?'320014':'384416' -->
                <router-link :to="{path: 'details', query: {  id: item.goods_id }}" target="_blank">
                    <img class="banner-img" :src="imgPath+item.default_image" />
                </router-link>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>

    </div>
</template>

<script>
    require('swiper/dist/css/swiper.css');
    import {swiper, swiperSlide} from 'vue-awesome-swiper'
    import 'swiper/dist/css/swiper.css'

    // import {myBranner} from '@/views/homePage/home' // 首页banner接口地址
    import {getBanner} from '@/global/https'
    export default {
        components: {
            swiper,
            swiperSlide
        },
        data() {
            return {
                banner: [], // 轮播
                swiperOption: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                    loop: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                }
            }
        },
        //定义swiper对象
        computed: {
            swiper() {
                return this.$refs.mySwiper.swiper;
            }
        },
        mounted() {
            this.swiper.slideTo(0, 0, false);
            this.getPoster(); //获取轮播
        },
        methods: {
            // 获取轮播、品牌及品牌下的商品
            async getPoster() {
                let {code, msg, rows} = await getBanner({bannerType: '1'});
                if (code == 0) {
                    this.banner = rows; //轮播
                }
                console.log(this.banner, '1111')
            },
        }

    }
</script>
<style lang="less" scoped>
    .newGoods {
        width: 100%;
        height: 460px;

        img {
            width: 100%;
            height: 460px;
        }
    }
</style>
<style>
    .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        border: 3px solid #fff;
        background: none;
        opacity: 1;
        border-radius: 100%;
    }

    .swiper-pagination-bullet-active {
        background: #FFF54D;
        border: 3px solid #FFF54D;
    }
</style>